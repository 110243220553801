export const AUTH = "AUTH";
export const LOADING = "LOADING";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";

/* //packs constants
export const GET_ALL_PAKCS = "GET_ALL_PAKCS";
export const GET_PACK_DETAILS = "GET_PACK_DETAILS";
export const GET_BOOKS_IN_PACK = " GET_BOOKS_IN_PACK"; */

//Users constants
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const CREATE_USER = "CREATE_USER";

//Approve User
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS";
export const APPROVE_TEACHER_SUCCESS = "APPROVE_TEACHER_SUCCESS";

//Teachers constants
export const GET_ALL_TEACHERS = "GET_ALL_TEACHERS";
export const GET_TEACHER = "GET_TEACHER";
export const EDIT_TEACHER = "EDIT_TEACHER";
export const DELETE_TEACHER = "DELETE_TEACHER";
export const CREATE_TEACHER = "CREATE_TEACHER";

//Packs constants
export const GET_ALL_PACKS = "GET_ALL_PACKS";
export const GET_PACK = "GET_PACK";
export const EDIT_PACK = "EDIT_PACK";
export const DELETE_PACK = "DELETE_PACK";
export const CREATE_PACK = "CREATE_PACK";
export const GET_BOOKS_IN_PACK = "GET_BOOKS_IN_PACK";
export const ADD_BOOKS_IN_PACK = "ADD_BOOKS_IN_PACK";
export const DELETE_BOOKS_FROM_PACK = "DELETE_BOOKS_FROM_PACK";

//Books constants
export const GET_ALL_BOOKS = "GET_ALL_BOOKS";
export const GET_BOOK = "GET_BOOK";
export const EDIT_BOOK = "EDIT_BOOK";
export const DELETE_BOOK = "DELETE_BOOK";
export const CREATE_BOOK = "CREATE_BOOK";

//Sessions constants
export const GET_ALL_SESSIONS = "GET_ALL_SESSIONS";
export const GET_SESSION = "GET_SESSION";
export const EDIT_SESSION = "EDIT_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";
export const CREATE_SESSION = "CREATE_SESSION";
export const GET_SESSIONS_FROM_BOOK = "GET_SESSIONS_FROM_BOOK";

//FollowedPacks constants
export const GET_ALL_FOLLOWED_PACKS = "GET_ALL_FOLLOWED_PACKS";
export const APPROVE_FOLLOWED_PACK = "APPROVE_FOLLOWED_PACK";
export const DELETE_FOLLOWED_PACK = "DELETE_FOLLOWED_PACK";
export const REJECT_FOLLOWED_PACK = "REJECT_FOLLOWED_PACK";

//FollowedSessions constants
export const GET_ALL_FOLLOWED_SESSIONS = "GET_ALL_FOLLOWED_SESSIONS";
export const APPROVE_FOLLOWED_SESSION = "APPROVE_FOLLOWED_SESSION";
export const DELETE_FOLLOWED_SESSION = "DELETE_FOLLOWED_SESSION";
export const REJECT_FOLLOWED_SESSION = "REJECT_FOLLOWED_SESSION";

//QUizs constants
export const GET_ALL_QUIZS = "GET_ALL_QUIZS";
export const GET__QUIZS_IN_SESSION = "GET__QUIZS_IN_SESSION";
export const ADD__QUIZS_TO_SESSION = "ADD__QUIZS_TO_SESSION";
export const DELETE__QUIZS_FROM_SESSION = "DELETE__QUIZS_FROM_SESSION";

