// ** Import from react dom
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";

// ** Import core SCSS styles
import "assets/scss/theme.scss";

import JobListingLayout from "layouts/marketing/JobListingLayout"; // ( added in v2.0.0 )
import Contact from "components/marketing/pages/contact/Contact"; // new v1.1.0
import LandingJob from "components/marketing/landings/landing-job/LandingJob"; // new v2.0.0

import { useEffect } from "react";
import { toast } from "react-toastify";

const AllRoutes = () => {
  const navigate = useNavigate();
  // const { user, is_authenticated } = useSelector((state) => state.auth);
  const user = {
    role: "admin",
  };
  useEffect(() => {
    if (user?.role) {
      if (user.role === "reader") {
        navigate("/");
        toast.error("You cant login as reader");
      }
    }
  }, [user?.role]);

  const privateRoute = (element) => {
    return user?.role === "admin" ? element : <Navigate to="/" />;
  };

  return (
    <Routes>
      {/* Routes with JobListingLayout */}
      <Route element={<JobListingLayout />}>
        <Route path="/" element={<LandingJob />} />
      </Route>
      <Route path="/contact-us/" element={<Contact />} />
      {/*Redirect*/}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AllRoutes;
