import { AUTH, LOADING, LOGIN, LOGOUT } from "../actions/constants";

const authReducer = (
  state = { is_authenticated: false, loading: false },
  action
) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case AUTH:
      localStorage.setItem("user", JSON.stringify({ ...action?.payload }));
    case LOGIN:
      return {
        ...state,
        user: action?.payload,
        is_authenticated: true,
      };
    case LOGOUT:
      localStorage.removeItem("user");

      return { ...state, user: null, is_authenticated: false };
    default:
      return state;
  }
};

export default authReducer;
